import { Container } from "../ui-elements/basic-ui";
import { PlusMinusButton } from "../ui-elements/theme-button";
import { formatPrice, formatPriceDifference } from "../util/format";
import useTranslate from "../util/dictionary";

export default function ProductCard({
    product, style, sideways, lineAmt, openModal, fontSize = 1,
    amount, variants, addAction, removeAction
}) {
    if (sideways) {
        return SidewaysProductCard({
            product, style, lineAmt, openModal, fontSize, amount, variants, addAction, removeAction
        });
    }

    return (
        <>
            <Card
                product={product}
                style={style}
                lineAmt={lineAmt}
                openModal={openModal}
                fontSize={fontSize}
                amount={amount}
                bottom={
                    <div>
                        <VariantsView product={product} variants={variants}/>
                        <div style={{minHeight: "50px", padding: "0.2em"}}>
                            <div style={{
                                color: "var(--highlight)",
                                fontSize: "0.5em",
                                width: "65%"
                            }}>
                                <OrderedVariantsView variants={variants} product={product}/>
                            </div>
                            <div
                                style={{
                                    position: "absolute",
                                    right: "0.2em",
                                    bottom: "0.2em",
                                }}
                            >
                                <PlusMinusButton
                                    add={addAction}
                                    remove={removeAction}
                                    amount={amount || 0}
                                />
                            </div>
                        </div>
                    </div>
                }
            />
        </>
    );
}

export function Card({product, style, lineAmt, openModal, fontSize = 1, amount, bottom, greyed}) {
    return (
        <Container
            style={{
                width: "12em",
                minWidth: "12em",
                padding: 0,
                textAlign: "left",
                position: "relative",
                userSelect: "none",
                cursor: "pointer",
                marginLeft: 0,
                marginRight: "32px",
                opacity: greyed ? 0.5 : 1,
                ...style
            }}
            onClick={openModal ? () => openModal(product) : () => {}}
        >
            { product.image &&
                <div
                    style={{
                        backgroundColor: "white",
                        width: "100%",
                        height: "6em",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                    }}
                >
                    <img
                        src={product.image}
                        style={{
                            height: "100%",
                            margin: "auto"
                        }}
                    />
                </div>
            }
            <div style={{ display: "flex" }}>
                <div 
                    style={{
                        flex: 3,
                        paddingLeft: `${8 * fontSize}px`,
                        overflow: "hidden", 
                         margin: "0.5em"
                    }}
                >
                    <h4
                        style={{
                            fontSize: 20 * fontSize
                        }}
                    >{product.name}</h4>
                    {
                        product.alcohol_content !== null &&
                        product.alcohol_content !== undefined &&
                        product.alcohol_content !== "" ?
                        <h6
                            style={{
                                fontSize: 15 * fontSize,
                                color: "var(--highlight)"
                            }}
                        >
                            {product.alcohol_content.toFixed(1)}%
                        </h6> :
                        null
                    }
                </div>
                <div style={{
                    flex: 1,
                    textAlign: "right",
                    paddingRight: `${8 * fontSize}px`
                }}>
                    <h4
                        className="highlight"
                        style={{
                            margin: "0.5em",
                            marginBottom: 0,
                            fontSize: 20 * fontSize
                        }}
                    >
                        {formatPrice(product.price + (product.split_tax?.price || 0))}
                    </h4>
                    <h6
                        style={{
                            marginRight: "0.5em",
                            marginBottom: "0.5em",
                            fontSize: 15 * fontSize
                        }}
                    >
                        {product.size}
                    </h6>
                </div>
            </div>
            <div
                style={{
                    display: '-webkit-box', /* For webkit browsers */
                    WebkitBoxOrient: 'vertical',
                    WebkitLineClamp: lineAmt ? lineAmt : 3, /* Show two lines of text */
                    overflow: 'hidden',
                    marginLeft: 4
                }}
            >
                <p
                    style={{
                        marginLeft: "0.5em",
                        marginTop: "0em",
                        fontSize: fontSize * 14,
                        paddingLeft: "8px",
                        paddingRight: "8px",
                        maxWidth: "100%",
                        whiteSpace: "pre-line"
                    }}
                >{product.description}</p>
            </div>
            <div style={{alignSelf: "flex-end", marginBottom: "0.25em"}}>
                {bottom}
            </div>
        </Container>
    );
}

function SidewaysProductCard({
    product, style, openModal, fontSize = 1,
    amount, variants, addAction, removeAction
}) {
    return (
        <SidewaysCard
            product={product}
            style={style}
            openModal={openModal}
            fontSize={fontSize}
            amount={amount}
            variants={variants}
            bottom={
                <>
                    {variants ?
                        <div style={{
                            flex: 2,
                            color: "var(--highlight)",
                            textAlign: "center",
                            fontSize: "0.5em",
                        }}>
                            <OrderedVariantsView variants={variants} product={product}/>
                        </div>
                        :
                        <div style={{
                            flex: 2,
                            textAlign: "right",
                            paddingRight: `${8 * fontSize}px`,
                            minWidth: "4em"
                        }}>
                            <div style={{ float: "right" }}>
                                <PlusMinusButton
                                    add={addAction}
                                    remove={removeAction}
                                    amount={amount || 0}
                                />
                            </div>
                        </div>
                    }
                </>
            }

        />
    );
}

export function SidewaysCard ({product, style, openModal, fontSize, bottom}) {
    return (
        <Container
            style={{
                width: "100%",
                minWidth: "100%",
                minHeight: "4em",
                padding: 0,
                textAlign: "left",
                position: "relative",
                userSelect: "none",
                cursor: "pointer",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: "16px",
                display: "flex",
                flexDirection: "row"
            }}>
            {product.image && 
                <div
                    style={{
                        flex: 2,
                        overflow: "hidden",
                        height: "4em",
                        backgroundColor: "white"
                    }}
                >
                    <img
                        src={product.image}
                        style={{
                            height: "100%",
                            objectFit: "cover",
                            objectPosition: "center"
                        }}
                    />
                </div>
            }
            <div style={{ flex: 3, paddingLeft: `${8 * fontSize}px` }}>
                <h4 style={{ margin: 0, fontSize: 25 * fontSize }}>{product.name}</h4>
                <h6
                    className="highlight"
                    style={{ margin: 0, fontSize: 18 * fontSize }}
                >{formatPrice(product.price + (product.split_tax?.price || 0))}</h6>
            </div>
            <div style={{flex: 3, textAlign: "left"}}>
                {bottom}
            </div>
        </Container>
    );
}

function VariantsView ({product, variants}) {

    if(product.variants.length === 0) {
        return null;
    }

    //Check if there are price changes between variants as that will tell if price is shown or not

    const priceChanges = product.variants.reduce((acc1, variant) => {

        const hasPriceChanges = variant.options.reduce((acc2, option) => {
            if(option.price && option.price !== 0) {
                return true;
            } else {
                return acc2;
            }
        }, false);

        if(hasPriceChanges) {
            return true;
        } else {
            return acc1;
        }
    }, false);

    return (
        <div style={{marginTop: "1em"}}>
        {
            product.variants.map(variant => {
                return (
                    <div key={variant._id}>
                        <p
                            style={{
                                marginBottom: "0.25em",
                                marginLeft: ".6em",
                                marginTop: "0.5em",
                                fontSize: "0.6em",
                                color: "var(--highlight)"
                            }}
                        >
                            {variant.title}:
                        </p>
                        <div
                            style={{
                                display: "flex", 
                                flexWrap: "wrap",
                                marginLeft: ".5em"
                            }}
                        >
                            {
                                variant.options.map(option => { 
                                    const optPrice = option.price && option.price !== 0;
                                    
                                    const amount = variants.filter(v => v.find(opt => opt.name === option.name)).length;
                                    
                                    return (
                                        <div
                                            key={option._id}
                                            style={{
                                                height: priceChanges ? "3em" : "1em",
                                                minWidth: "3em",
                                                margin: "0.2em",
                                                fontSize: ".6em",
                                                padding: "0.3em",
                                                textAlign: "center",
                                                color: "var(--light)"
                                            }}
                                        >
                                            <p>{option.name}</p>
                                            {
                                                priceChanges &&
                                                <p 
                                                    style={{
                                                        marginTop: "0.25em",
                                                        fontSize: "1.25em",
                                                        color: "var(--highlight)",
                                                        opacity: optPrice ? 1 : 0.8
                                                    }}
                                                >
                                                    {optPrice ? formatPrice(product.price + (product.split_tax?.price || 0) + option.price) : formatPrice(product.price + (product.split_tax?.price || 0))}
                                                </p>
                                            }
                                        </div>
                                    );  
                                })
                            }
                        </div>
                    </div>
                );
            })
        }
        </div>
    );
}

function OrderedVariantsView ({variants, product}) {
    const tr = useTranslate();
    return (
        <div style={{marginLeft: "1em", textAlign: "left"}}>
            { variants.length > 0 && <p style={{marginTop: "1em"}}>{tr("orders")}:</p> }
            {
                variants.map((variant, i) => {
                    const price = variant.reduce((acc, v) => acc + v.price, 0);
                    return (
                        <p key={i}>
                            <em>
                                {
                                    variant.map(v => v.name).join(" - ")
                                } {
                                    price && price !== 0 ?
                                        formatPrice(product.price + (product.split_tax?.price || 0) + price) :
                                        ""
                                }
                            </em>
                        </p>
                    );
                })
            }
        </div>
    );
}