import { useState, useEffect } from 'react';

function useisMobileDevice() {
    const [isMobileDevice, setIsMobileDevice] = useState(false);

    useEffect(() => {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;
        const mobileCheck = /android|iphone|ipad|ipod|opera mini|iemobile|wpdesktop|blackberry/i.test(userAgent);
    
        setIsMobileDevice(mobileCheck);
      }, []);

    return isMobileDevice;
}

export default useisMobileDevice;