import { useNavigate } from "react-router-dom";
import BackButton from '../common/backbutton';
import useTranslate from '../util/dictionary';

const navStyle = {
  width: "10em",
  backgroundColor: "inherit",
  color: "var(--light)",
  margin: 0,
  height: "100%"
};

export default function OrderNavBar(props) {
  const { openCounter, closeCounter, open } = props;
  const tr = useTranslate();
  const navigateTo = useNavigate();
  return (
    <div style={{
      backgroundColor: "var(--medium)",
      textAlign: "left",
      height: "3em",
      padding: 0,
      display: "flex"
    }}>
      <BackButton
        override={() => navigateTo("../../../restaurant")}
        style={{
          position: "relative",
          top: 0,
          margin: 0,
          height: "100%"
        }}
      />
      {
        open ? 
        (
          <button
              onClick={() => {
                  closeCounter();
              }}
              style={navStyle}
          >
              {tr("close_counter")}
          </button>
        ) : (
          <button
              onClick={() => {
                  openCounter();
              }}
              style={navStyle}
          >
              {tr("open_counter")}
          </button>
        )
      }
      <button
        onClick={() => {
          navigateTo("history");
        }}
        style={navStyle}
      >
        {tr("history")}
      </button>
      <button
        onClick={() => {
          if (!document.fullscreenElement) {
            if (document.documentElement.requestFullscreen) {
              document.documentElement.requestFullscreen();
            }
          } else {
              if (document.exitFullscreen) {
                  document.exitFullscreen();
              }
          }
        }}
        style={navStyle}
      >
        {tr("fullscreen")}
      </button>
    </div>
  );
}