import { useRef } from "react";
import useTranslate from "../util/dictionary";
import { Input, Icon } from '../ui-elements/basic-ui';
import ThemeButton from '../ui-elements/theme-button';
import { localId } from "../util/functions";

export function VariantsListings({ product }) {
    const tr = useTranslate();
    return (
        <div style={{ marginLeft: "0.5em", marginTop: "0.25em" }}>
            <p style={{ fontSize: "0.5em" }}>
                {tr("variants_short")}:
            </p>
            <p style={{ fontSize: "0.4em" }}>
                {
                    product.variants.length > 0 ? (
                        product.variants.map((variant) =>
                            <span key={variant._id} style={{ margin: 0, display: "block" }}>{variant.title + ": " + variant.options.map(opt => opt.name).join(", ")}</span>
                        )
                    ) : (
                        <span style={{ color: "var(--light)" }}>{
                            tr("no_variants")
                        }</span>
                    )
                }
            </p>
        </div>
    );
}

export function VariantsInput({ variants, setVariants, defaultPrice }) {
    const tr = useTranslate();
    const updateData = (variant, option, field, prefunc) => {
        return (e) => {
            setVariants(
                variants.map(v => {
                    if (variant._id === v._id) {
                        v.options.map(o => {
                            if (option._id === o._id) {
                                o[field] = prefunc ? prefunc(e.target.value) : e.target.value;
                            }
                            return o;
                        });
                    }
                    return v;
                })
            )
        };
    };

    const deleteVariant = (id) => {
        setVariants(variants.filter(v => v._id !== id));
    }

    return (
        <div>
            <label>{tr("variants")}</label>
            <br />
            {
                variants.map((variant) => (
                    <div key={variant._id} style={{
                        border: "5px dotted var(--highlight)",
                        marginTop: "1em",
                        padding: "1em"
                    }}>
                        <div style={{display: "flex", justifyContent: "flex-end", width: "100%"}}>
                            <ThemeButton icon="delete" onClick={() => deleteVariant(variant._id)}/>
                        </div>
                        <Input
                            required
                            label="variant"
                            autoComplete=""
                            autoFocus
                            divStyle={{ flex: 1, marginRight: "0.5em" }}
                            value={variant.title}
                            onChange={(e) => {
                                setVariants(
                                    variants.map(v => {
                                        if (v._id === variant._id) {
                                            v.title = e.target.value;
                                        }
                                        return v;
                                    })
                                )
                            }}
                        />
                        {variant.options.map((option) => {

                            const priceDifferenceInput = useRef(null);
                            const priceInput = useRef(null);

                            return (
                                <div
                                    style={{ display: "flex", minWidth: "20em", alignItems: "center"}}
                                    key={option._id}
                                >
                                    <Input
                                        divStyle={{ margin: "0.5em", flex: 3, minWidth: "6em"}}
                                        required
                                        label="option_name"
                                        autoComplete=""
                                        value={option.name}
                                        onChange={updateData(variant, option, "name")}
                                    />
                                    <Input
                                        divStyle={{ margin: "0.5em", flex: 3, minWidth: "6em"}}
                                        label="price_difference"
                                        type="number"
                                        step="0.05"
                                        autoComplete=""
                                        defaultValue={(option.price / 100).toFixed(2)}
                                        onChange={(e) => {
                                            updateData(variant, option, "price", n => n * 100)(e);
                                            if(priceInput.current) {
                                                priceInput.current.value = (parseFloat(e.target.value) + defaultPrice / 100).toFixed(2);
                                            }
                                        }}
                                        ref={priceDifferenceInput}
                                    />
                                    <Input
                                        divStyle={{ margin: "0.5em", flex: 3, minWidth: "6em"}}
                                        label="price"
                                        type="number"
                                        step="0.05"
                                        autoComplete=""
                                        defaultValue={((defaultPrice + option.price) / 100).toFixed(2)}
                                        onChange={(e) => {
                                            updateData(variant, option, "price", n => (n * 100 - defaultPrice))(e);
                                            if(priceDifferenceInput.current) {
                                                priceDifferenceInput.current.value = (parseFloat(e.target.value) - defaultPrice / 100).toFixed(2);
                                            }
                                        }}
                                        ref={priceInput}
                                    />
                                    <Input
                                        divStyle={{ margin: "0.5em", flex: 3, minWidth: "6em"}}
                                        label="description"
                                        autoComplete=""
                                        defaultValue={option.description}
                                        onBlur={updateData(variant, option, "description")}
                                    />
                                    <ThemeButton icon="delete" onClick={() => setVariants(
                                        variants.map(v => {
                                            if (variant._id === v._id) {
                                                v.options = v.options.filter(o => o._id !== option._id);
                                            }
                                            return v;
                                        })
                                    )}/>
                                </div>
                            );
                        })}
                        <ThemeButton
                            icon="plus"
                            onClick={
                                () => setVariants(
                                    variants.map(v => {
                                        if (variant._id === v._id) {
                                            v.options.push({
                                                ...localId(),
                                                description: "", name: "", price: 0,
                                            });
                                        }
                                        return v;
                                    })
                                )
                            }
                        />
                    </div>
                ))
            }
            <ThemeButton
                icon="plus"
                onClick={
                    //Add variant with two empty options
                    () => setVariants(
                        [...variants, {
                            ...localId(),
                            title: "",
                            options: [
                                {
                                    ...localId(),
                                    description: "",
                                    name: "",
                                    price: 0,
                                },
                                {
                                    ...localId(),
                                    description: "",
                                    name: "",
                                    price: 0,
                                },
                            ]
                        }]
                    )
                }
            />
        </div>
    );
}