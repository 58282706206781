import { Input } from '../ui-elements/basic-ui';
import { Card } from "../customer/productCard";
import ThemeButton from '../ui-elements/theme-button';
import { CheckboxSelect, SelectItem } from '../ui-elements/select';
import { useEffect, useState } from 'react';
import useTranslate from "../util/dictionary";
import { capitalize } from '../util/format';
import productService from "../services/business/productService";
import { imageBank } from "../util/imageBank";
import { ImageUpload } from "../tools/ImageUpload/index";
import { getToken } from "../services/auth";
import DeleteButton from '../ui-elements/delete-button';
import { useLanguageContext } from "../context/language";
import uuid from "react-uuid";
import Modal from "../ui-elements/modal";
import { getTranslatedTag } from "../util/functions";
import { Icon } from '../ui-elements/basic-ui';
import { VariantsListings, VariantsInput } from './variants';
import useisMobileDevice from '../hooks/mobileDeviceHook';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const reorganizeButtonStyle = {
    width: "4em",
    height: "2em",
    padding: "0.05em",
    marginLeft: "1em",
    backgroundColor: "var(--dark)",
    border: "3px solid var(--highlight)",
    marginBottom: "0.5em"
};

export default function Product({ product, setProducts, allTags, allCounters, style, disableReorder, reorganize }) {
    const tr = useTranslate();
    const [edit, setEdit] = useState(false);
    const [errors, setErrors] = useState({});
    const [reorganizing, setReorganizing] = useState(false);

    const isMobileDevice = useisMobileDevice();

    const editProduct = async (productData) => {
        setEdit(false);

        try {
            const res = await productService.update(
                product._id.toString(),
                productData
            );
            setProducts(products => [...products.map(p => p._id === product._id ? res : p)]);
        } catch (e) {
            console.log("ERROR ", e);
            const errorData = e.response.data;

            if (typeof errorData === "object") {
                if (Array.isArray(errorData)) {
                    setErrors(errorData.reduce((acc, error) => ({ ...acc, ...error }), {}));
                } else {
                    setErrors(errorData);
                }

            } else {
                setErrors({ general: errorData });
            }
        }
    };

    const setVisible = async (visible) => {
        try {
            await productService.setVisibility(product._id, visible);
            setProducts(products => products.map(p => p._id === product._id ? { ...p, visible } : p));
        } catch (e) {
            console.log("ERROR ", e);
            setErrors({ general: e.response.data });
        }
    };

    const deleteProduct = async (event) => {
        event.stopPropagation();

        try {
            const res = await productService.delete(product._id);
            setProducts(products => products.filter(p => p._id !== product._id));
        } catch (e) {
            console.log("ERROR ", e);
            setErrors({ delete: errorData });
        }
    };

    return (
        <div style={style}>   
            <Card
                product={product}
                greyed={product.visible === false}
                fontSize={1}
                style={{minHeight: "15em"}}
                bottom={
                    <>
                        <div style={{display: "flex"}}>
                            <div style={{flex: 5, paddingLeft: "8px"}}>
                                {product.alcohol_content !== null && product.alcohol_content !== undefined ?
                                    <p style={{fontSize: "0.5em", paddingLeft: "8px", margin: 0}}>{tr("alcohol_content")}: {product.alcohol_content}%</p> : null}
                                <TagListing product={product}/>
                                <CounterListing product={product} allCounters={allCounters}/>
                                <VariantsListings product={product} />
                            </div>
                            <div style={{
                                textAlign: "right",
                                paddingRight: "0.4em",
                                flex: 4,
                                display: "grid",
                                gridTemplateColumns: "repeat(2, 1fr)",
                                gridTemplateRows: "repeat(2, 1fr)",
                                justifyContent: "flex-end",
                                alignItems: "flex-end"
                            }}>
                                <ThemeButton
                                    style={{marginTop: "0.5em"}}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setEdit(true)
                                    }}
                                    icon="edit"
                                    title={tr("edit")}
                                />
                                <DeleteButton
                                    style={{marginTop: "0.5em"}}
                                    onDelete={deleteProduct}
                                    errors={errors}
                                    title={tr("delete")}
                                />
                                <ThemeButton
                                    style={{marginTop: "0.5em"}}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setVisible(product.visible === false)
                                    }}
                                    icon={product.visible !== false ? "visible_false" : "visible_true"}
                                    title={tr("change_visibility")}
                                />
                                <ThemeButton
                                    style={{marginTop: "0.5em"}}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setReorganizing(!reorganizing)
                                    }}
                                    disabled={disableReorder}
                                    icon="reorder"
                                    title={tr("reorganize")}
                                />
                            </div>
                        </div>
                        {
                            reorganizing &&
                            <div style={{padding: ".25em"}}>
                                <div style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    textAlign: "center"
                                }}>
                                    <button
                                        style={reorganizeButtonStyle}
                                        onClick={() => reorganize(product._id, -1)}
                                    >
                                        <Icon icon="arrow-up"/>
                                    </button>
                                    <button
                                        style={reorganizeButtonStyle}
                                        onClick={() => reorganize(product._id, 1)}
                                    >
                                        <Icon icon="arrow-down"/>
                                    </button>
                                </div>
                                {!isMobileDevice && <p style={{margin: "1em", fontSize: "0.6em"}}>{tr("can_also_dragdrop")}</p>}
                            </div>
                        }
                    </>
                }
            />
            <Modal open={edit} onClose={() => setEdit(false)} style={{zIndex: 1000}}>
                <ProductForm
                    product={product}
                    handleSubmit={editProduct}
                    errors={errors}
                    submitText="update_product"
                    allCounters={allCounters}
                    allTags={allTags}
                />
            </Modal>
        </div>
    );
}
const uploaded_images_url = "/api/images/";
const image_bank_url = "/image_bank/";

export function ProductForm({
    product, handleSubmit, errors, submitText, allCounters, allTags
}) {

    const { language } = useLanguageContext();
    const [productCounters, setProductCounters] = useState([]);
    const [productTags, setProductTags] = useState([]);

    useEffect(() => {
        if(product) {
            const productCountersData = allCounters
                .filter(counter => product.counters
                    .includes(counter.passcode));
            setProductCounters(productCountersData);
        } else {
            //if creating a new product, set all counters as default
            setProductCounters(allCounters);
        }
    }, [
        allCounters
    ]);

    useEffect(() => {
        if(!product) return;
        const productTagsData = allTags
            .filter(tag => product.tags.map(tag => tag._id).includes(tag._id))
        setProductTags(productTagsData);
    }, [
        allTags
    ]);

    const tr = useTranslate();
    let imageBankDropdownDefault = "";
    let uploadedImageIdDefault = uuid();
    let imageUploadedDefault = null;
    if (product && product.image) {
        if (product.image.startsWith(uploaded_images_url)) {
            uploadedImageIdDefault = product.image.replace(uploaded_images_url, "");
            imageUploadedDefault = true;
        } else if (product.image.startsWith(image_bank_url)) {
            imageBankDropdownDefault = product.image.replace(image_bank_url, "").replace(".jpg", "");
        }
    }

    /*const [imageBankDropdown, setImageBankDropdown] = useState(imageBankDropdownDefault);
    const [uploadedImageId, setUploadedImageId] = useState(uploadedImageIdDefault);

    const [imageUploaded, setImageUploaded] = useState(imageUploadedDefault);
    const [isEditing, setIsEditing] = useState(false);*/

    const [variants, setVariants] = useState(product && product.variants ? product.variants : []);
    const [name, setName] = useState(product && product.name ? product.name : "");
    const [price, setPrice] = useState(product && product.price ? product.price : 0);
    const [tax, setTax] = useState(product && product.tax ? product.tax : 25.5);
    const [splitTaxVisible, setSplitTaxVisible] = useState(product && product.split_tax);
    const [splitTaxDescription, setSplitTaxDescription] = useState(product && product.split_tax?.description ? product.split_tax?.description : "");
    const [splitTax, setSplitTax] = useState(product && product.split_tax?.vat ? product.split_tax?.tax : 25.5);
    const [splitTaxPrice, setSplitTaxPrice] = useState(product && product.split_tax?.price ? product.split_tax?.price : 0);

    useEffect(() => {
        if(splitTaxVisible === false) {
            setSplitTaxDescription("");
            setSplitTaxPrice(0);
            setSplitTax(25.5);
        }
    }, [splitTaxVisible]);

    const handleChangeCounters = (counter, checked) => {
        if(checked) {
            setProductCounters(productCounters => [...productCounters, counter]);
        } else {
            setProductCounters(productCounters.filter(c => c._id !== counter._id));
        }
    };

    const handleChangeTags = (tag, checked) => {
        if(checked) {
            setProductTags(productTags => [...productTags, tag]);
        } else {
            setProductTags(productTags.filter(t => t._id !== tag._id));
        }
    };

    const submit = (event) => {
        event.preventDefault();
        /*
        let image = "";
        if (uploadedImageId && imageUploaded) {
            image = uploaded_images_url + uploadedImageId;
        } else if (imageBankDropdown) {
            image = image_bank_url + imageBankDropdown + ".jpg";
        }*/

        const data = new FormData(event.currentTarget);

        const productData = {
            product: {
                name: data.get("name"),
                price: data.get("price") * 100,
                ...(splitTaxVisible ?
                    {
                        split_tax: {
                            description: data.get("split_tax_description"),
                            tax: data.get("split_tax_vat"),
                            price: data.get("split_tax_price") * 100
                        }
                    } : {split_tax: null}
                ),
                tax: data.get("tax"),
                description: data.get("description"),
                variants,
                //image,
                tags: productTags.map(tag => tag._id),
                size: data.get("size"),
                alcohol_content: data.get("alcohol_content"),
                counters: productCounters.map(counter => counter.passcode)
            }
        };

        handleSubmit(productData);
        
        /*if (!product || !product._id)
            setUploadedImageId(uuid());*/
    }

    // Prices in creating and editing works with different values
    // (creating: eur, editing: cents) - add dirty hack to show correct values
    const parsePreTaxPrice = () => {
        const priceInCents = price * ((100 - tax) / 100);
        return (priceInCents / 100).toFixed(2);
    }

    const parsePreTaxSplitTaxPrice = () => {
        const priceInCents = splitTaxPrice * ((100 - splitTax) / 100);
        return (priceInCents / 100).toFixed(2);
    }

    return (
        <form onSubmit={submit} style={{marginTop: "1em"}}>
            <>
                {/*{!isEditing ?
                    (
                        <>
                            <label>{tr("image_bank_select")}</label>
                            <CheckboxSelect
                                single
                                id="imageSelect"
                                value={
                                    <img src={`/image_bank/${imageBankDropdown}.jpg`}
                                    width={48}
                                    height={48}/>}
                                onChange={(e) => setImageBankDropdown(e.target.value)}
                                style={{ textAlign: "center", height: "2em", width: "100%" }}
                            >
                                {
                                    imageBank.map(val => (
                                        <SelectItem
                                            key={val}
                                            value={val}
                                            style={{
                                                width: "33%",
                                                display: "inline-block",
                                                textAlign: "center"
                                            }}
                                            onSelect={() => setImageBankDropdown(val)}
                                        >
                                            <img
                                                src={`/image_bank/${val}.jpg`}
                                                width={48}
                                                height={48}
                                                style={{marginTop: "0.5em"}}
                                            />
                                            <span style={{fontSize: "0.5em", display: "block"}}>{tr(val)}</span>
                                        </SelectItem>
                                    ))
                                }
                            </CheckboxSelect>
                        </>
                    ) :
                    null
                }
                <>
                    <label>{tr("or_upload")}</label>
                    <ImageUpload
                        hasImage={imageUploaded}
                        setHasImage={image => setImageUploaded(image)}
                        setIsEditing={setIsEditing}
                        src={`/api/images/${uploadedImageId}`}
                        content={tr("drag_and_drop_image_here")}
                        url={`/api/images/${uploadedImageId}`}
                        deleteurl={`/api/images/${uploadedImageId}`}
                        resolution={800}
                        width={400}
                        style={{ padding: 0 }}
                        aspect="1"
                        onError={onError}
                        minify
                        minifiedWidth={"100%"}
                        minifiedHeight={100}
                        token={getToken()}
                    />
                </>*/}
            </>
            <Input
                required
                id="name"
                label="product_name"
                name="name"
                autoComplete=""
                error={errors["name"]}
                defaultValue={product ? product.name : ""}
                autoFocus
                onChange={(e) => setName(e.target.value)}
            />
            <Input
                type="number"
                step="0.01"
                required
                name="price"
                label="price"
                id="price"
                autoComplete=""
                error={errors["price"]}
                defaultValue={product ? (product.price / 100).toFixed(2) : ""}
                onChange={(e) => setPrice(e.target.value * 100)}
            />
            <Input
                type="number"
                step="0.1"
                required
                name="tax"
                label="tax"
                id="tax"
                autoComplete=""
                error={errors["tax"]}
                defaultValue={product ? product.tax : 25.5}
                onChange={(e) => setTax(e.target.value)}
            />
            <Input
                type="number"
                step="1"
                disabled
                name="pre_tax_price"
                label="pre_tax_price"
                id="tax"
                title={tr("calculated_automatically")}
                autoComplete=""
                value={parsePreTaxPrice()}
            />
            <Input
                type="checkbox"
                name="split_tax"
                label="split_tax"
                id="split_tax"
                onChange={(e) => setSplitTaxVisible(!splitTaxVisible)}
                checked={splitTaxVisible}
                style={{border: "none"}}
            />
            {
                splitTaxVisible &&
                <div>
                    <Input
                        required
                        id="split_tax_description"
                        label="split_tax_description"
                        name="split_tax_description"
                        autoComplete=""
                        error={errors["split_tax_description"]}
                        autoFocus
                        onChange={(e) => setSplitTaxDescription(e.target.value)}
                        value={splitTaxDescription}
                    />
                    <Input
                        type="number"
                        step="0.01"
                        required
                        name="split_tax_price"
                        label="split_tax_price"
                        id="split_tax_price"
                        autoComplete=""
                        error={errors["split_tax_price"]}
                        onChange={(e) => {
                            const inputValue = e.target.value;
                            // Update state with the raw input or the multiplied value
                            setSplitTaxPrice(inputValue === "" ? "" : parseFloat(inputValue) * 100);
                        }}
                        value={splitTaxPrice !== "" ? (splitTaxPrice / 100).toFixed(2) : ""}
                    />
                    <Input
                        type="number"
                        step="1"
                        required
                        name="split_tax_vat"
                        label="split_tax_vat"
                        id="split_tax_vat"
                        autoComplete=""
                        error={errors["split_tax_vat"]}
                        defaultValue={product ? product.split_tax?.vat : 25.5}
                        onChange={(e) => setSplitTax(e.target.value)}
                        value={splitTax}
                    />
                    <label>{tr("receipt_example")}</label>
                    <table>
                        <tbody>
                            <tr>
                                <td>{tr("product_name")}</td>
                                <td>{tr("pre_tax_price")}</td>
                                <td>{tr("vat")}</td>
                                <td>{tr("price")}</td>
                            </tr>
                            <tr>
                                <td>{name}</td>
                                <td>{parsePreTaxPrice()}</td>
                                <td>{tax}</td>
                                <td>{(price / 100).toFixed(2)}</td>
                            </tr>
                            <tr>
                                <td>{splitTaxDescription}</td>
                                <td>{parsePreTaxSplitTaxPrice()}</td>
                                <td>{splitTax}</td>
                                <td>{(splitTaxPrice / 100).toFixed(2)}</td>
                            </tr>
                            <tr>
                                <td>{capitalize(tr("total"))}:</td>
                                <td>{(parseFloat(parsePreTaxPrice()) + parseFloat(parsePreTaxSplitTaxPrice())).toFixed(2)}</td>
                                <td>-</td>
                                <td>{((price + splitTaxPrice) / 100).toFixed(2)}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            }
            <div style={{width: "100%"}}>
                <Input
                    label="description optional"
                    textarea
                    id="description"
                    name="description"
                    autoComplete=""
                    defaultValue={product ? product.description : ""}
                />
            </div>
            <Input
                id="size"
                label="size optional"
                name="size"
                autoComplete=""
                defaultValue={product ? product.size : ""}
                error={errors["size"]}
            />
            <Input
                id="alcohol_content"
                label="alcohol_content optional"
                name="alcohol_content"
                autoComplete=""
                defaultValue={product ? product.alcohol_content : ""}
                error={errors["alcohol_content"]}
                type="number"
                step="0.1"
            />

            <label>{tr("tags")}</label>
            <CheckboxSelect value={productTags.map(tag => tag?.name).join(", ")} keepOpen>
                {allTags.map((tag) => (
                    <SelectItem key={tag._id} value={tag.name}>
                        <div style={{
                            display: "flex",
                            alignItems: "center"
                        }}>
                            <input type="checkbox"
                                id={tag._id}
                                checked={
                                    productTags.some(productTag => tag._id === productTag._id)
                                }
                                onChange={(e) => {     
                                    e.stopPropagation();
                                    handleChangeTags(tag, e.target.checked)
                                }}
                            />
                            <label htmlFor={tag._id}> 
                            {getTranslatedTag(tag, language)}
                            </label>
                        </div>
                    </SelectItem>
                ))}

            </CheckboxSelect>
            <label>{tr("product_counters")}</label>
            <CheckboxSelect
                value={productCounters.map(counter => counter.counterName ? `${counter.barName} - ${counter.counterName}` : counter.barName).join(", ")}
                keepOpen
            >
            {
                    allCounters.map((counter) => (
                        <SelectItem key={counter._id}>
                            <div style={{
                                display: "flex",
                                alignItems: "center"
                            }}>
                                <input type="checkbox"
                                    id={counter._id}
                                    checked={
                                        productCounters.some(productCounter =>      
                                            productCounter._id === counter._id
                                        )
                                    }
                                    onChange={(e) => {
                                        e.stopPropagation();
                                        handleChangeCounters(counter, e.target.checked)
                                    }}
                                />
                                <label htmlFor={counter._id}> 
                                {counter.counterName ? `${counter.barName} - ${counter.counterName}` : counter.barName}
                                </label>
                            </div>
                        </SelectItem>
                    ))
                }
            </CheckboxSelect>
            <VariantsInput
                variants={variants}
                setVariants={setVariants}
                defaultPrice={price}
            />
            <button type="submit">
                {tr(submitText)}
            </button>
            {
                errors["general"] ?
                    <Alert severity="error">{tr(errors["general"])}</Alert> :
                    null
            }
        </form>
    );
}

function TagListing({product}) {
    const { language } = useLanguageContext();
    const tr = useTranslate();
    return (
        <div style={{display: "flex", flexWrap: "wrap"}}>
            {product.tags.length > 0 ? (
                product.tags.map(tag => (
                    <span
                        key={tag._id}
                        style={{
                            backgroundColor: "var(--dark)",
                            color: "var(--light)",
                            padding: 4,
                            marginLeft: 4,
                            marginTop: 1,
                            textAlign: "center",
                            fontSize: "0.5em",
                        }}
                    >
                        {getTranslatedTag(tag, language)}
                    </span>
                ))
            ) : (
                <span style={{
                    color: "white",
                    fontSize: "0.5em",
                    marginLeft: "1em"
                }}>
                    {tr("no_category")}
                </span>
            )}
        </div>
    );
}

function CounterListing ({product, allCounters}) {
    const tr = useTranslate();
    return (
        <div style={{marginLeft: "0.5em", marginTop: "0.25em"}}>
            <p style={{fontSize: "0.5em"}}>
                {tr("product_counters")}:
            </p>
            <p style={{fontSize: "0.4em"}}>
            {
                product.counters.length > 0 ? (
                    allCounters
                        .filter(counter => product.counters.includes(counter.passcode))
                        .map((counter, i) => counter.counterName ? `${counter.barName} - ${counter.counterName}` : counter.barName
                        ).join(", ")

                ) : (
                    <span style={{ color: "var(--light)" }}>{
                        tr("no_counters")
                    }</span>
                )
            }
            </p>
        </div>
    );
}