export const termsOfDeliveryTextFi = <div>
<p>Seinelake Tmi 2805909-8 (jäljempänä verkkokauppa) myy tuotteita yksityishenkilöille Suomeen. Pidätämme oikeuden toimitusehtojen ja hintojen muutoksiin. Tuotteiden hinnat sisältävät arvonlisäveron.</p>

<b>Verkkokaupan yhteystiedot</b>

<p>Sähköposti: <a href="mailto:peetu@jonos.io">peetu@jonos.io</a></p>
<p>Puhelin: <a href="tel:+358 50 4650 905">050 4650 905</a></p>
<p>Postiosoite: Pönkänkarintie 10, 90580 Oulu</p>

<b>Tilaaminen</b>

<p>Tilattavat tuotteet valitaan verkkosivuilla lisäämällä ne ostoskoriin. Tilaus lähetetään maksamalla ostoskorin sisältö verkkokaupan kassatoiminnossa. Tehdessäsi tilauksen hyväksyt nämä toimitusehdot, tuotteiden hinnat sekä toimituskulut. Mikäli tilaushetkellä annetaan sähköpostiosoite, tilauksesta lähetetään tilausvahvistus sähköpostitse. Tilausvahvistuksesta ilmenevät tilatut tuotteet sekä hinta.</p>

<b>Maksaminen</b>

<p>Verkkokaupan maksuvälittäjänä toimii Visma Pay (Visma Payments Oy, y-tunnus 2486559-4), joka on rekisteröity Finanssivalvonnan ylläpitämään maksulaitosrekisteriin. Maksamiseen siirrytään Visma Payn verkkopalvelun kautta ja tiliotteella ja laskulla maksun saajana näkyy Visma Pay tai Visma Payments Oy. Visma Pay välittää maksut verkkokauppiaalle. Maksaminen on turvallista, sillä kaikki maksutapahtumaa koskevat tiedot välitetään salattua yhteyttä käyttäen niin ettei kukaan ulkopuolinen taho näe maksutapahtuman tietoja.</p>

<p>Kauppa syntyy verkkokaupan asiakkaan ja verkkokaupan välille. Verkkokaupan vastuulla ovat kaikki kauppaan liittyvät velvoitteet.</p>

<p>Lue lisää Visma Paysta: <a href="https://www.vismapay.fi/" target="_blank">https://www.vismapay.fi/</a></p>

<b>Maksutavat</b>

<p>Visma Pay -palvelun kautta voit maksaa verkkopankkitunnuksilla, lompakolla, maksukorteilla (credit/debit), laskulla tai osamaksulla. Käytettävissä ovat seuraavat maksutavat: Osuuspankki, Nordea, Danske Bank, Oma Säästöpankki, Säästöpankki, Aktia, Paikallisosuuspankit, S-Pankki, Handelsbanken, Ålandsbanken, MobilePay, Siirto, Visa-, Visa Debit-, Visa Electron-, MasterCard- ja Debit MasterCard -kortit sekä Alisa Lasku.</p>

<p>MobilePay: Voit maksaa MobilePay-lompakollasi mikäli olet sallinut verkkokaupoissa maksamisen sovelluksen asetuksista. MobilePay-lompakolla suoritetut maksut veloitetaan lompakkoon liitetyltä maksukortilta. Mikäli maksun veloittaminen maksukortilta epäonnistuu, MobilePay-lompakolla maksaminen ei ole mahdollista verkkokaupassa.</p>

<p>
OP Lasku - Joustava tapa maksaa verkko-ostokset. Kaikkien pankkien asiakkaille.
OP Laskulla voit tehdä enintään 5 000 euron suuruisia ostoksia ja maksaa ne korottomasti pois 45 vuorokauden sisällä.
Halutessasi voit pilkkoa laskun useampaan maksuerään. Ostoksesi näkyvät yhdessä paikassa, joten pysyt helposti perillä rahan käytöstä.
Luottorajan avulla pidät kulutuksen haluamissasi rajoissa. Saat laskut kätevästi sähköpostiisi.
Verkkopalvelussamme näet tietoja OP Laskun käytöstäsi ja tekemiesi ostosten summan.
Voit käyttää OP Laskua, jos olet maksukykyinen yli 20-vuotias, ja sinulla on suomalaisen pankin verkkotunnukset.
Lisätietoja OP Laskuun liittyen löydät osoitteesta: 
<a href="https://www.op.fi/henkiloasiakkaat/paivittaiset/maksaminen/op-lasku" target="_blank"> https://www.op.fi/henkiloasiakkaat/paivittaiset/maksaminen/op-lasku</a></p>

<p>Alisa Lasku on joustava ja turvallinen maksutapa. Maksutapa sopii kaikille yli 20-vuotiaille kuluttajille kaiken tyyppisiin ostoksiin. Halutessasi voit jakaa laskun pienempiin osiin ja maksaa takaisin jopa 60 erässä. Lisätietoja Alisa Laskuun liittyen löydät osoitteesta: <a href="https://www.alisapankki.fi/" target="_blank">https://www.alisapankki.fi</a></p>

<b>Visma Pay -maksupalvelun yhteystiedot</b>

<p>Visma Payments Oy (Y-tunnus 2486559-4)</p>
<p>Sähköposti: <a href="mailto:helpdesk@vismapay.com">helpdesk@vismapay.com</a></p>
<p>Puhelin: <a href="tel:09 315 42 037">09 315 42 037</a> (arkisin klo 9-16)</p>
<p>Postiosoite: Brahenkatu 4, 53100 Lappeenranta</p>
</div>;

export const termsOfDeliveryTextEn = `

`;