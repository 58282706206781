import { useState, useEffect, useRef } from 'react';
import catcher from '../util/catcher';
import Product, { ProductForm } from './product';
import productService from '../services/business/productService';
import Modal from "../ui-elements/modal";
import TagModal from "./tagModal";
import ThemeButton from "../ui-elements/theme-button";
import { Input, Icon } from "../ui-elements/basic-ui";
import useTranslate from '../util/dictionary';
import { useLanguageContext } from '../context/language';
import organisationService from '../services/business/organisationService';
import { getTranslatedTag, organizeByCategories } from '../util/functions';
import { ReactSortable } from "react-sortablejs";
import { toastError } from '../util/toast';
import useIsMobileDevice from '../hooks/mobileDeviceHook';


export default function ProductController() {
    const tr = useTranslate();
    const { language } = useLanguageContext();
    
    const [addFormOpen, setAddFormOpen] = useState(false);
    const [errors, setErrors] = useState({});
    const [products, setProducts] = useState([]);
    const [counters, setCounters] = useState([]);
    const [tags, setTags] = useState([]);
    const [tagModalOpen, setTagModalOpen] = useState(false);
    const [search, setSearch] = useState("");
    //Reorder is quite server heavy so if you order products too fast the server updates late so we ignore the older response
    const latestReorder = useRef(0);
    
    const isMobileDevice = useIsMobileDevice();

    useEffect(() => {
        const getCounters = async () => {
            const organisation = await organisationService.get();
            setCounters(organisation.counters);
            setTags(organisation.tags);
        };
        getCounters();
    }, [
    ]);

    useEffect(() => {
        catcher(async () => {
            const products = await productService.get();
            setProducts(products);
        });
    }, []);

    const addProduct = async (productData) => {
        try {
            const res = await productService.create(productData);
            setProducts(res);
            setAddFormOpen(false);
            document.documentElement.scrollTop = 0;

        } catch (e) {
            console.log("ERROR ", e);
            const errorData = e.response.data;

            if (typeof errorData === "object") {
                if (Array.isArray(errorData)) {
                    setErrors(errorData.reduce((acc, error) => ({ ...acc, ...error }), {}));
                } else {
                    setErrors(errorData);
                }
            } else {
                setErrors({ general: errorData });
            }
        }
    };

    const productsInCategories = organizeByCategories(products);

    const reorganizeProductsDragAndDrop = (tag) => {
        return async (newOrderFull) => {

            if(!productsInCategories[tag]) return;

            const old = [...products]
            const oldOrder = productsInCategories[tag].products.map(product => product._id);
            const newOrder = newOrderFull.map(item => item._id);

            //if old and new order aren't the same
            if(JSON.stringify(oldOrder) !== JSON.stringify(newOrder)) {

                const allOtherProducts = products.filter(product => !(product.tags?.[0]?.name === tag || (tag === "Other" && !product.tags?.[0])));
                //sort all other products by their priority
                const allOtherProductsSorted = allOtherProducts.sort((a, b) => {
                    const aTag = a.tags?.[0];
                    const bTag = b.tags?.[0];

                    if(aTag && bTag) {
                        if(aTag.priority === bTag.priority) {
                            return aTag.priority - bTag.priority;
                        } else {
                            return a.priority - b.priority;
                        }
                    } else if(aTag) {
                        return -1;
                    } else if(bTag) {
                        return 1;
                    } else {
                        return a.priority - b.priority;
                    }
                });

                const allProducts = [...newOrderFull, ...allOtherProductsSorted];
                //Now sort all projects only by tag to ignore internal priorities of the sorted products because those will be reorganized
                const allProductsSortedFull = allProducts.sort((a, b) => {
                    const aTag = a.tags?.[0];
                    const bTag = b.tags?.[0];

                    if(aTag && bTag) {
                        if(aTag.priority === bTag.priority) {
                            return aTag.priority - bTag.priority;
                        } else {
                            return 0;
                        }
                    } else if(aTag) {
                        return -1;
                    } else if(bTag) {
                        return 1;
                    } else {
                        return 0;
                    }
                });

                const allProductsSorted = allProductsSortedFull.map(item => item._id);

                try {
                    setProducts(allProductsSortedFull);
                    const newProducts = await productService.reorganizeProducts({newOrder: allProductsSorted});
                    setProducts(newProducts);
                } catch (e) {
                    toastError(tr("something_went_wrong"));
                    setProducts(old);
                }
            }
        };
    };

    const reorganizeProductsArrowKeys = async (product, direction) => {
        const oldOrder = [...products];
        const movingElementIdx = oldOrder.findIndex(p => p._id.toString() === product);

        const newOrder = [...oldOrder];


        if(movingElementIdx + direction < 0 && movingElementIdx + direction >= newOrder.length)
            return;

        const movingProduct = products.find(p => p._id.toString() === product);

        const checkAndMove = (idx) => {
            if(
                (newOrder[idx].tags?.[0] === undefined && movingProduct.tags?.[0] === undefined) ||
                newOrder[idx].tags?.[0]._id === movingProduct.tags?.[0]._id
            ) {
                newOrder.splice(movingElementIdx, 1);
                newOrder.splice(idx, 0, movingProduct);
                return true;
            } else {
                return false;
            }
        };

        if(direction === 1) {
            //find next product in the same category
            for(let idx = movingElementIdx + 1; idx < newOrder.length; idx++) {
                const moved = checkAndMove(idx);
                if(moved) break;
            }
        } else if (direction === -1) {
            //find previous product in the same category
            for(let idx = movingElementIdx - 1; idx >= 0; idx--) {
                const moved = checkAndMove(idx);
                if(moved) break;
            }
        }

        try {
            latestReorder.current++;
            const scopeLatestReorder = latestReorder.current;

            setProducts(newOrder);
            const newProducts = await productService.reorganizeProducts({newOrder: newOrder.map(p => p._id)});
            console.log(scopeLatestReorder, latestReorder.current, scopeLatestReorder === latestReorder.current);
            if(scopeLatestReorder === latestReorder.current) {
                setProducts(newProducts);
            }
        } catch (e) {
            toastError(tr("something_went_wrong"));
            console.log("ERROR ", e);
            setProducts(oldOrder);
        }
    };

    return (
        <>
            {
                !addFormOpen ?
                    <>
                        <div style={{display: "flex"}}>
                            <div style={{width: "7em", marginLeft: ".5em"}}>
                                <button
                                    onClick={() => setAddFormOpen(true)}
                                >
                                    {tr("create_product")}
                                </button>
                            </div>
                            <div style={{width: "7em", marginLeft: ".5em"}}>
                                <button onClick={() => setTagModalOpen(true)}>
                                    {tr("view_tags")}
                                </button>
                            </div>
                            <div style={{display: "flex", width: "9em", marginLeft: ".5em", alignItems: "center"}}>
                                <Icon icon="search" style={{marginTop: ".5em"}}/>
                                <input
                                    type="text"
                                    placeholder={tr("search")}
                                    style={{
                                        height: "3.8em",
                                        marginTop: "1.1em",
                                        marginLeft: "0.5em"
                                    }}
                                    onChange={(e) => setSearch(e.target.value)}
                                    value={search}
                                />
                            </div>
                        </div>
                        <div style={{textAlign: "left", marginTop: "1em"}}>
                            {
                                search.length > 0 ?
                                    <>
                                    <h4
                                        className="highlight"
                                        style={{
                                            marginTop: "1em",
                                            marginLeft: "1em",
                                            marginBottom: ".5em",
                                            fontSize: "0.9em"
                                        }}
                                    >{tr("search")}: {search}...</h4>
                                        <div style={{display: "flex", flexWrap: "wrap", marginLeft: "1em"}}>
                                        {
                                            products.filter(product => product.name.toLowerCase().includes(search.toLowerCase())).map(product =>
                                                <Product
                                                    key={product._id}
                                                    product={product}
                                                    setProducts={setProducts}
                                                    allTags={tags}
                                                    allCounters={counters}
                                                    style={{
                                                        display: "inline-block",
                                                        margin: "0.5em"
                                                    }}
                                                    disableReorder={true}
                                                />
                                            )
                                        }
                                        </div>
                                    </>
                                :
                                tags.concat({name: "Other", translations: {"fi": "Muut"}})
                                .map(tag => {
                                    if(
                                        !productsInCategories?.[tag.name]?.products ||
                                        productsInCategories?.[tag.name]?.products.length === 0
                                    ) {
                                        return null;
                                    } else {
                                        const productsInCategoriesDOM = productsInCategories?.[tag.name]?.products.map((product) => (
                                            <div key={product._id}>
                                                <Product
                                                    product={product}
                                                    setProducts={setProducts}
                                                    allTags={tags}
                                                    allCounters={counters}
                                                    style={{
                                                        display: "inline-block",
                                                        margin: "0.5em"
                                                    }}
                                                    reorganize={reorganizeProductsArrowKeys}
                                                    />
                                            </div>
                                        ));
                                        return (
                                            <div key={tag._id} style={{minHeight: "10em"}}>
                                                <h4
                                                    className="highlight" 
                                                    style={{
                                                        marginTop: "4px",
                                                        marginLeft: "24px",
                                                        marginBottom: ".5em",
                                                        fontSize: "0.9em"
                                                    }}
                                                >
                                                    {tags.length !== 0 ? getTranslatedTag(tag, language) : ""}
                                                </h4>
                                                <div>
                                                    {
                                                        !isMobileDevice ?
                                                            <ReactSortable
                                                                list={productsInCategories?.[tag.name]?.products || []}
                                                                setList={reorganizeProductsDragAndDrop(tag.name)}
                                                                style={{display: "flex", flexWrap: "wrap", marginLeft: "1em"}}
                                                                >
                                                                {productsInCategoriesDOM}
                                                            </ReactSortable> :
                                                            <div>
                                                                {productsInCategoriesDOM}
                                                            </div>
                                                    }
                                                </div>
                                            </div>
                                        );
                                    }
                                })
                            }
                            
                        </div>
                    </>
                    :
                    <div style={{ backgroundColor: "var(--dark)", padding: 16, maxWidth: "20em", margin: "auto" }}>
                        <button onClick={() => setAddFormOpen(false)} style={{width: "8em"}}>{tr("back")}</button>
                        <ProductForm
                            product={null}
                            handleSubmit={addProduct}
                            errors={errors}
                            submitText="create_product"
                            allTags={tags}
                            allCounters={counters}
                        />
                    </div>
            }
            {
                errors["general"] ?
                    <Alert severity="error">{tr(errors["general"])}</Alert> :
                    null
            }
            <TagModal
                tagModalOpen={tagModalOpen}
                setTagModalOpen={setTagModalOpen}
                tags={tags}
                setTags={setTags}
            />
        </>
    );
}

